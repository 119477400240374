<template>
  <div class="content containers" style="min-height: 100vh; padding: 0">
    <navbar @asideShow="asideFlagFun"></navbar>
    <div style="padding: 10px">
      <div class="whiteBg">
        <div class="whiteBg_item">
          <p style="color: #333333; font-size: 30px;margin-bottom:20px;">
            {{ $t("invite.welcome") }}
          </p>
          <div>
            <van-image
              width="120px"
              height="120px"
              :src="require('@/assets/xxImage/index/logo@2x.png')"
            />
          </div>
          <div class="qrcode">
            <qrcode-vue :value="inviterUrl" size="160"></qrcode-vue><br />
          </div>
          <div class="word">{{ $t("invite.link") }}</div>
          <div class="link">
            {{ inviterUrl }}
          </div>
          <div
            class="btn"
            v-clipboard:copy="inviterUrl"
            v-clipboard:success="firstCopySuccess"
            v-clipboard:error="firstCopyError"
          >
            {{ $t("invite.copyLink") }}
          </div>
        </div>
      </div>
    </div>
    <asidePopup
      v-if="asideFlag"
      @close="noShow"
      :shows="asideFlag"
    ></asidePopup>
  </div>
</template>
<script>
import asidePopup from "@/components/common/asidePopup";
import QrcodeVue from "qrcode.vue";
import storage from "storejs";
import { Toast } from "vant";

export default {
  components: { asidePopup, QrcodeVue },
  data() {
    return {
      asideFlag: false,
      inviterUrl: "",
    };
  },
  mounted() {
    this.$store.dispatch("getSetting"); //网站配置
    this.getInviter_code();
  },
  methods: {
    noShow() {
      this.asideFlag = false;
    },
    asideFlagFun() {
      this.asideFlag = true;
    },
    asideFlagShow(e) {
      this.asideFlag = e;
    },
    // 邀请连接
    getInviter_code(){
      this.$axios.get(this.$api.inviter_code, {}).then((res) => {
        this.inviterUrl = res.data.inviterUrl;
      });
    },
    firstCopySuccess(){
      Toast(
        storage.get("lang") == "zh" ? "複製成功！" : "Successful replication！"
      );
    },
    firstCopyError() {
      Toast(storage.get("lang") == "zh" ? "複製失敗！" : "Successful Fail");
    },
  },
};
</script>
<style lang="scss" scoped>
.whiteBg {
  width: 100%;
  height: 600px;
  border: 1px solid #000;
  border-radius: 20px;
  background-color: #e2e2ff;
  .whiteBg_item {
    width: 100%;
    height: 567px;
    border: 1px solid #e2e2ff;
    border-radius: 20px;
    // background-color: #e2e2ff;
  }
}
.qrcode {
  width: 191px;
  height: 191px;
  border: 1px solid #000;
  border-radius: 5px;
  margin: 0 auto;
  padding: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}
.content {
  background: linear-gradient(90deg, #B79AFA, #8867FE);
  // background: linear-gradient(180deg, #7857fe, #8867fe);
  text-align: center;
  padding: 15px;
  p {
    margin: 40px 0 29px;
    font-size: 30px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #ffffff;
  }
  .word {
    margin-top: 20px;
    color: black;
    font-size: 15px;
    font-family: Source Han Sans CN;
    font-weight: 500;
  }
  .link {
    margin: 19px 0 27px;
    font-size: 10px;
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: black;
  }
  .btn {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    width: 250px;
    height: 40px;
    // height: 39px;
    border: 1px solid #000;
    // background: #8f92fa;
    background: linear-gradient(90deg, #B79AFA, #8867FE);
    border-radius: 10px;
    font-size: 15px;
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: #ffffff;
  }
}
.list {
  /deep/.van-cell::after {
    border-bottom: 1px solid #eaeaea;
  }
}
/deep/ .cell-title {
  font-size: 15px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: #333333;
  text-align: left;
}
</style>